export const API_BASE_URL = `https://j0pf5n0i0e.execute-api.us-east-1.amazonaws.com/Stage`;
export const API_BASE_URL_V2 = `https://2yum2ypg1j.execute-api.us-east-1.amazonaws.com/Stage`;
export const API_BASE_URL_DEV = `https://dn6i8gjtra.execute-api.us-east-1.amazonaws.com/dev`;
const API_APP = `${API_BASE_URL}/app`;

export const POST_CREATE_APP = API_APP;
export const GET_MY_APPS = API_APP;
export const GET_APP_DETAILS = API_APP;
export const POST_CHAT = `${API_BASE_URL_DEV}/chat`;
export const GET_CHAT_HISTORY = `${API_BASE_URL_DEV}/chat`;
export const PUT_APP_DETAILS = API_APP;
export const POST_EARLY_ACCESS = `${API_BASE_URL_V2}/earlyaccess`;
export const SUBSCRIPTIONS = `${API_BASE_URL}/subscriptions`;
export const POST_ADD_FUNCTION = `${API_BASE_URL}/function`;
export const POST_ADD_UISTORAGE = `${API_BASE_URL}/uistorage`;
