import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchUserAttributes } from "aws-amplify/auth";

import { navigate } from "gatsby";
import { useTheme, useMediaQuery } from "@mui/material";
import {
  Typography,
  Box,
  Skeleton,
  ListItemText,
  ListItemButton,
  ListItem,
  List,
  Toolbar,
  Divider,
  Button,
  Tooltip,
  IconButton,
  Popover,
} from "@mui/material";

import DashboardIcon from "@mui/icons-material/Dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import { AlignVertCenter } from "../../AlignVertCenter";
import { Logo } from "../../Logo";
import { useGetAuthToken } from "../../../hooks/useGetAuthToken";
import packageJson from "../../../../package.json";

import { setIsLoggedIn } from "../../../store/reducers/user.reducer";
import { setAppDetails } from "../../../store/reducers/myappdetails.reducer";
import { setSubscription } from "../../../store/reducers/myplan.reducer";
import {
  AUTH,
  DASHBOARD,
  CREATE_APP,
  MY_PLAN,
  MY_APPS,
} from "../../../constants/routes";
import { SUBSCRIPTIONS } from "../../../constants/api";

const navigationItems = [
  { label: "Dashboard", route: `${AUTH}${DASHBOARD}`, icon: <DashboardIcon /> },
  {
    label: "Create App",
    route: `${AUTH}${CREATE_APP}`,
    icon: <AddCircleIcon />,
  },
  { label: "My Apps", route: `${AUTH}${MY_APPS}`, icon: <ListAltIcon /> },
  { label: "My Plan", route: `${AUTH}${MY_PLAN}`, icon: <CreditCardIcon /> },
];

import {
  DrawerHeader,
  Drawer,
  StyledAppBar,
  StyledListItemButton,
  StyledListItemIcon,
} from "./AuthenticatedLayout.styles";

import { setEmail } from "../../../store/reducers/user.reducer";

interface AuthenticatedLayoutProps {
  children: React.ReactNode;
}

export const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({
  children,
}) => {
  const { token } = useGetAuthToken();

  const user = useSelector((state: any) => state.userReducer);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { authStatus, signOut } = useAuthenticator((context) => [
    context.authStatus,
    context.signOut,
  ]);
  const [userEmailLoading, setUserEmailLoading] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const popoverIsOpen = Boolean(anchorEl);
  const id = popoverIsOpen ? "simple-popover" : undefined;

  const handleClickOpenPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickClosePopover = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    signOut();
    dispatch(setIsLoggedIn(false));
  };

  const handleClickNavigate = (path: string) => {
    if (path === CREATE_APP) {
      dispatch(setAppDetails({}));
    }
    navigate(path);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isSmall) {
      setOpen(false);
    }
  }, [isSmall]);

  useEffect(() => {
    async function handleFetchUserAttributes() {
      try {
        const userAttributes = await fetchUserAttributes();

        dispatch(setEmail(userAttributes?.email || null));
        setUserEmailLoading(false);
      } catch (error) {
        console.log(error);
        setUserEmailLoading(false);
      }
    }
    if (authStatus === "authenticated") {
      dispatch(setIsLoggedIn(true));
      handleFetchUserAttributes();
    } else {
      signOut();
    }
  }, [authStatus]);

  useEffect(() => {
    async function getSubscription() {
      const api = `${SUBSCRIPTIONS}?email=${user.email}`;
      const config = { headers: { Authorization: `Bearer ${token}` } };

      try {
        const response = await axios.get(api, config);
        const responseBody = JSON.parse(response.data.body);

        dispatch(setSubscription(responseBody)); // Dispatch the action with the response
      } catch (error) {
        console.error("Failed to get subscription", error);
      }
    }

    if (token) {
      getSubscription();
    }
  }, [dispatch, token, user.email]);

  if (authStatus !== "authenticated") return null;

  return (
    <Box sx={{ display: "flex" }}>
      <StyledAppBar open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box>
            <Box display="flex">
              <Logo icon />

              <AlignVertCenter height={42}>
                <Box ml={1}>
                  <Typography variant="caption" noWrap>
                    {`v${packageJson.version}`}
                  </Typography>
                </Box>
              </AlignVertCenter>
            </Box>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Box display="flex" justifyContent={`flex-end`}>
              <AlignVertCenter height={32}>
                {userEmailLoading ? (
                  <Box height={28} width={175}>
                    <Skeleton animation="wave" />
                  </Box>
                ) : (
                  <Button
                    endIcon={<ArrowDropDownIcon />}
                    size="small"
                    onClick={handleClickOpenPopover}
                  >
                    {user.email}
                  </Button>
                )}
              </AlignVertCenter>
              <Popover
                id={id}
                open={popoverIsOpen}
                anchorEl={anchorEl}
                onClose={handleClickClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <List>
                  <ListItemButton
                    onClick={() => handleClickNavigate(DASHBOARD)}
                  >
                    Dashboard
                  </ListItemButton>

                  <Divider />
                  <ListItemButton onClick={handleLogout}>Logout</ListItemButton>
                </List>
              </Popover>
            </Box>
          </Box>
        </Toolbar>
      </StyledAppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ padding: 0 }}>
          {navigationItems.map((item) => {
            const isActive = false;

            return (
              <Tooltip title={item.label} key={item.label} placement="right">
                <ListItem
                  key={item.label}
                  disablePadding
                  sx={{
                    display: "block",
                  }}
                >
                  <StyledListItemButton
                    onClick={() => handleClickNavigate(item.route)}
                  >
                    <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                    <ListItemText
                      primary={item.label}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </StyledListItemButton>
                </ListItem>
              </Tooltip>
            );
          })}
        </List>
        <Divider />
        <Tooltip title="Logout" placement="right">
          <ListItem
            disablePadding
            sx={{
              display: "block",
            }}
          >
            <StyledListItemButton>
              <StyledListItemIcon>
                <LogoutIcon />
              </StyledListItemIcon>
              <ListItemText primary={`Logout`} sx={{ opacity: open ? 1 : 0 }} />
            </StyledListItemButton>
          </ListItem>
        </Tooltip>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>
    </Box>
  );
};
