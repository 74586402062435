import React from "react";
import { Box } from "@mui/material";

import { Header } from "../../Header";
import { Footer } from "../../Footer";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const HEADER_SPACE = "92px";

  return (
    <>
      <Header />
      <Box component="main" flexGrow={1}>
        <Box pt={HEADER_SPACE}>{children}</Box>
      </Box>
      <Footer />
    </>
  );
};
