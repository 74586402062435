import { uid } from "uid";
import {
  AUTH,
  ABOUT,
  HOME,
  EARLY_ACCESS,
  DASHBOARD,
  ROADMAP,
  PRICING,
} from "../../constants/routes";

export const navigation = [
  { id: uid(), title: "Home", route: HOME },
  { id: uid(), title: "About", route: ABOUT },
  { id: uid(), title: "Roadmap", route: ROADMAP },
  // { id: uid(), title: "Pricing", route: PRICING },
  // { id: uid(), title: "Contact", route: CONTACT },
  { id: uid(), title: "Early Access", route: EARLY_ACCESS },
  { id: uid(), title: "Login", route: `${AUTH}${DASHBOARD}` },
];
