import React from "react";
import { uid } from "uid";
import { IconButton } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";

import {
  SOCIAL_TELEGRAM,
  SOCIAL_LINKEDIN,
  SOCIAL_TWITTER,
} from "../../constants/social";
interface SocialData {
  id: string;
  url: string;
  icon: keyof typeof SocialIconMap;
  title: string;
}

const SocialIconMap = {
  linkedin: LinkedInIcon,
  instagram: InstagramIcon,
  facebook: FacebookIcon,
  telegram: TelegramIcon,
  twitter: TwitterIcon,
};

const socialData: SocialData[] = [
  {
    id: uid(),
    url: SOCIAL_TELEGRAM,
    icon: "telegram",
    title: "Telegram",
  },
  {
    id: uid(),
    url: SOCIAL_TWITTER,
    icon: "twitter",
    title: "Twitter",
  },
  {
    id: uid(),
    url: SOCIAL_LINKEDIN,
    icon: "linkedin",
    title: "LinkedIn",
  },
];

export const Social: React.FC = ({ ...props }) => {
  return (
    <>
      {socialData.map(({ id, url, icon, title }) => (
        <IconButton
          href={url}
          target="_blank"
          rel="noreferrer"
          key={id}
          size="large"
          sx={{ color: "#fff" }}
          aria-label={`social medial link to ${title}`}
        >
          {React.createElement(SocialIconMap[icon])}
        </IconButton>
      ))}
    </>
  );
};
