import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SubscriptionState {
  more_info_url: string;
  subscriptions: Array<{
    subscription_id: string;
    status: string;
  }>;
  recent_charges: Array<{
    charge_id: string;
    amount: number; // Assuming the amount is in the smallest currency unit (e.g., cents)
    currency: string;
    created: string;
  }>;
}

const initialState: SubscriptionState = {
  more_info_url: "",
  subscriptions: [],
  recent_charges: [],
};

export const myAppDetailsSlice = createSlice({
  name: "myAppDetails",
  initialState,
  reducers: {
    setSubscription: (state, action: PayloadAction<SubscriptionState>) => {
      // Ensure the payload structure matches the SubscriptionState interface
      state.more_info_url = action.payload.more_info_url;
      state.subscriptions = action.payload.subscriptions;
      state.recent_charges = action.payload.recent_charges;
    },
    clearSubscription: () => initialState,
  },
});

export const { setSubscription, clearSubscription } = myAppDetailsSlice.actions;

export default myAppDetailsSlice.reducer;
