export const AUTH = `/auth`;
export const ABOUT = `/about`;
export const CONTACT = `/contact`;
export const CREATE_API = `/create-api`;
export const CREATE_APP = `/create-app`;
export const DASHBOARD = `/dashboard`;
export const DEMO = `/demo`;
export const EARLY_ACCESS = `/early-access`;
export const NEWS_LETTER = `/news-letter`;
export const HOME = `/`;
export const MY_APPS = `/my-apps`;
export const MY_PLAN = `/my-plan`;
export const PRICING = `/pricing`;
export const ROADMAP = `/roadmap`;
export const PRIVACY_POLICY = `/privacy-policy`;
export const SCREENSMITH = `/screensmith`;
