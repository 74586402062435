import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

import ImgBg from "../../../images/bg-100.jpg";

interface UnauthenticatedLayoutProps {
  children: React.ReactNode;
}

export const UnauthenticatedLayout: React.FC<UnauthenticatedLayoutProps> = ({
  children,
}) => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${ImgBg})`, // Adjust the path as needed
          backgroundAttachment: "fixed",
          backgroundPosition: `center ${-offsetY * 0.5}px`, // Adjust the parallax speed by modifying the multiplier
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          zIndex: -1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
