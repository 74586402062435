import React, { useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useDispatch } from "react-redux";
import { Layout } from "./Layout";
import { AuthenticatedLayout } from "./AuthenticatedLayout";
import { UnauthenticatedLayout } from "./UnauthenticatedLayout";

import { setEmail, setSub } from "../../store/reducers/user.reducer";
interface LayoutProviderProps {
  children: React.ReactNode;
}

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { authStatus } = useAuthenticator();
  const { user } = useAuthenticator((context) => [context.user]);
  const authenticated = authStatus === "authenticated";

  useEffect(() => {
    if (user) {
      dispatch(setEmail(user.signInDetails?.loginId));
      dispatch(setSub(user.userId));
    }
  }, [user]);

  if (authenticated) {
    return <AuthenticatedLayout>{children}</AuthenticatedLayout>;
  } else {
    return (
      <UnauthenticatedLayout>
        <Layout>{children}</Layout>
      </UnauthenticatedLayout>
    );
  }
};
