import { ThemeOptions, createTheme } from "@mui/material/styles";
import { primaryColor, secondaryColor } from "./colors";
import { headlineFont, bodyFont, customFont } from "./fonts";
// Define common typography and components configurations
const commonTypography = {
  fontFamily: bodyFont,
  button: {
    fontFamily: customFont,
  },
  h1: {
    fontFamily: headlineFont,
    fontSize: "64px", // Set the font size for h1 elements
  },
  h2: { fontFamily: headlineFont, fontSize: "42px" },
  h3: { fontFamily: headlineFont, fontSize: "36px" },
  h4: { fontFamily: headlineFont },
  h5: { fontFamily: headlineFont },
  h6: { fontFamily: customFont },
};

const commonComponents = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        color: "white",
        borderRadius: 0,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        "& label": {
          color: "white",
        },
        "& label.Mui-focused": {
          color: "#c978c9",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#c978c9",
          },
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        "& .MuiSvgIcon-root": {
          color: "white",
        },
      },
      label: {
        color: "white",
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: (theme) => theme.palette.primary.main,
        "&:visited": {
          // Target visited links
          color: (theme) => theme.palette.primary.dark,
        },
      },
    },
  },
};

// Create a base theme configuration
const baseTheme: ThemeOptions = {
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  typography: commonTypography,
  components: commonComponents,
};

// Extend the base theme for light mode
export const theme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: "light",
  },
});

// Extend the base theme for dark mode
export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: "dark",
  },
});
