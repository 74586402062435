import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  AppBar,
  Container,
  ButtonBase,
  Typography,
  useScrollTrigger,
  useTheme,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { Logout } from "@mui/icons-material";
import { Logo } from "../Logo";
import { AlignVertCenter } from "../AlignVertCenter";

import { navigation } from "./Header.content";
import { DASHBOARD } from "../../constants/routes";

export const Header: React.FC = () => {
  const { authStatus, signOut } = useAuthenticator();
  const { isLoggedIn } = useSelector((state: any) => state.userReducer);
  const isAuthenticated = authStatus === "authenticated";
  const theme = useTheme();

  const [bgColor, setBgColor] = useState("transparent");
  const trigger = useScrollTrigger();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const appBarHeight = 92;

  function handleLoginText(title: string) {
    if (isLoggedIn) {
      return "Logout";
    } else {
      return title;
    }
  }

  const handleClickSignOut = () => {
    navigate(DASHBOARD);
    signOut();
  };

  const handleClickNavigate = (route: string) => {
    navigate(route);
    setDrawerOpen(false);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const renderDrawer = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={handleDrawerToggle}
      sx={{
        "& .MuiDrawer-paper": { backgroundColor: theme.palette.common.black },
      }}
    >
      <List>
        {navigation.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton onClick={() => handleClickNavigate(item.route)}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  useEffect(() => {
    setBgColor(trigger ? "#000000f0" : "#000");
  }, [trigger]);

  return (
    <Box sx={{ position: "relative", zIndex: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: bgColor,
          transition: "background-color 0.3s",
          zIndex: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
        elevation={0}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          mx={isSmall ? 2 : 10}
        >
          <Box display="flex">
            <AlignVertCenter height={appBarHeight}>
              <ButtonBase onClick={() => handleClickNavigate("/")}>
                <Logo />
              </ButtonBase>
            </AlignVertCenter>
          </Box>

          {isSmall ? (
            <IconButton color="inherit" edge="end" onClick={handleDrawerToggle}>
              <MenuIcon color="primary" />
            </IconButton>
          ) : (
            <Box display="flex">
              <AlignVertCenter height={appBarHeight}>
                <Box display="flex">
                  {navigation.map((item) => (
                    <Box ml={2} key={item.id}>
                      <Button
                        color="primary"
                        onClick={() => handleClickNavigate(item.route)}
                      >
                        {item.title}
                      </Button>
                    </Box>
                  ))}
                </Box>
              </AlignVertCenter>
              {isAuthenticated && (
                <AlignVertCenter height={appBarHeight}>
                  <Box ml={2}>
                    <ButtonBase onClick={handleClickSignOut}>
                      <Logout />
                    </ButtonBase>
                  </Box>
                </AlignVertCenter>
              )}
            </Box>
          )}
        </Box>
      </AppBar>
      {isSmall && renderDrawer()}
    </Box>
  );
};
