import { createSlice } from "@reduxjs/toolkit";
// Define the initial state
const initialState = {
  appDetails: {}, // Initialize as an empty object
  appFileNames: [],
  selectedAppFile: "",
  loading: false,
  error: null,
};
export const myAppappDetails = createSlice({
  name: "myAppappDetails",
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    setAppDetails: (state, action) => {
      state.appDetails = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateAppDetails: (state, action) => {
      // Assume action.payload contains the updated appDetails
      state.appDetails = { ...state.appDetails, ...action.payload };
      state.loading = false;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setSelectedAppFile: (state, action) => {
      state.selectedAppFile = action.payload;
    },
    setAppFileNames: (state, action) => {
      // Parse the payload for UI and lambda_functions to create the file names array
      const uiFiles = action.payload.ui.map((uiItem) => ({
        language: uiItem.language,
        fileName: uiItem.name,
      }));

      const lambdaFiles = action.payload.lambda_functions.map((lambdaItem) => ({
        language: lambdaItem.language,
        fileName: lambdaItem.name,
      }));

      const allFiles = [...uiFiles, ...lambdaFiles];
      const groupedByLanguage = allFiles.reduce((acc, file) => {
        if (!acc[file.language]) {
          acc[file.language] = {
            language: file.language,
            files: [],
          };
        }
        acc[file.language].files.push(file.fileName);
        return acc;
      }, {});

      state.appFileNames = Object.values(groupedByLanguage);
    },
  },
});

export const {
  startLoading,
  setAppDetails,
  updateAppDetails,
  setAppFileNames,
  setError,
  setSelectedAppFile,
} = myAppappDetails.actions;

export default myAppappDetails.reducer;
