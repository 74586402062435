import { rem } from "polished";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  backgroundColor: theme.palette.common.black,
  [theme.breakpoints.down("sm")]: {
    padding: `${rem(16)}`,
  },
}));

export { StyledFooter };
